import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '*',
            name:'other',
            component: () => import('@/components/desktop/board.vue')
        },
        {
            path: '/',
            name:'root',
            component: () => import('@/components/desktop/board.vue')
        },
    ]
})

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'


import '../public/fonts.css'


import 'es6-promise/auto'
import Vuex from 'vuex'
Vue.use(Vuex)
import store from './store'


import Routes from "@/configs/routes"
Vue.use({
  install () {
      Vue.Routes = Routes
      Vue.prototype.$Routes = Routes
  }
})

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('vue-date-picker', VuePersianDatetimePicker);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from "vue"
import Routes from "@/configs/routes"
export default {
  state: {
    site_url: BaseURL,
  },

  getters: {
    site: state => state,
    
  },

  mutations: {
    SET_SITE_OPTIONS(state, data) {
      state[data.key] = data.value;

      if (data.key == "site_name") {
        const titleEl = document.querySelector('head title');
        titleEl.textContent = data.value;
      }
    },
  },

  actions: {
    
  }
}
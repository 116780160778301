import Vuex from 'vuex'
import 'es6-promise/auto'
import site from './site'
import user from './user'

export default () => new Vuex.Store({
  modules: {
    site,
    user,
  }
})
import Vue from 'vue'

import router from '../router'

import Routes from "@/configs/routes"

import Notifications from 'vue-notification'

Vue.use(Notifications)


export default {
    state: {
    },

    getters: {
        user: state => state,
        axios(state) {
            return require('axios').create({
                withCredentials: true,
                headers: {
                    'X-Requested-With':'XMLHttpRequest',
                    // "Content-Type": 'multipart/form-data',
                },
                transformResponse: [
                    function (data) {
                        // Do whatever you want to transform the data
                        let d = JSON.parse(data)
                        return d;
                    },
                    function (data) {
                        if (data.messages instanceof Array && data.messages.length > 0) {
                            data.messages.forEach(e => {
                                console.log(e)
                                Vue.notify({
                                    group: 'site',
                                    type: data.code == 200 ? 'success' : 'error',
                                    text: e
                                })
                            });
                        }
                        return data
                    },
                    function (data) {
                        // Do whatever you want to transform the data
                        if (data.code == 303 || data.code == 301) {
                            // should ber redirect
                            window.location.assign(data.data)
                        }
                        return data;
                    }
                ],
            })
        },
    },

    mutations: {
        
    },

    actions: {
        setToken({
            commit,
            getters,
            dispatch,
            state
          }, token){
            state.token = token
        }
    },

}
const basePath = BaseURL

export default {
  getBaseUrl: () => (
    `${basePath}`
  ),

  getFormsFiles: () => (
    `${basePath}`
  ),
  
  
  getForm: (id) => (
    `${basePath}/forms/item/guest?id=${id}&rand=` + Math.random() //get
  ),
  getFormEntries: () => (
    `${basePath}/forms/form/entries/guest?rand=` + Math.random() //post
  ),
 
  getFormEntry: () => (
    `${basePath}/forms/form/entry/guest?rand=` + Math.random() //post
  ),
  getPaymentByPayToken: (token) => (
    `${basePath}/payment/guest?payToken=${token}&rand=` + Math.random() //post
  ),
  postPayment: () => (
    `${basePath}/payment?rand=` + Math.random() //post
  ),
  postCheckCoupon: () => (
    `${basePath}/payment/check_coupon?rand=` + Math.random() //post
  ),
  getFormVariables: () => (
    `${basePath}/forms/form/variables/guest?rand=` + Math.random() //post
  ),
  
  
  getUserDetail: () => (
    `${basePath}/forms/form/user/CheckUser/item?rand=` + Math.random() //get
  ),
  getLoginUser: () => (
    `${basePath}/forms/form/user/login/item?rand=` + Math.random() //get
  ),
  getverifyUser: () => (
    `${basePath}/forms/form/user/verify/item?rand=` + Math.random() //get
  ),
  putUpdateUser: () => (
    `${basePath}/forms/form/user/update/item?rand=` + Math.random() //put
  ),
  
  
  getLetMeKnow: () => (
    `${basePath}/forms/form/user/checkUser/letmeknow?rand=` + Math.random() //get
  ),

}



